import React, {useEffect} from "react";
import "../OurServicesCard/OurServicesCard.css";
import Aos from "aos";
import "aos/dist/aos.css";
import img from "../../assets/images/blindCleaning.jpg";

const OurServicesCard = () => {

  // use effect hook for scroll animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <section class="card-section">
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Cleaning Services and Upkeep</h3>
          <p class="card-paragraph">
          Keep your spaces clean and fresh with our reliable cleaning services. From regular upkeep to deep cleaning, we ensure a spotless environment using eco-friendly products.
          </p>
          <div class="card-image">
            {/* <div class="card-hover-content">
              <h4>Cleaning Services and Upkeep</h4>
              <p>Keep your spaces clean and</p>
            </div> */}
            <img
              src="https://cdn.pixabay.com/photo/2021/01/18/16/56/woman-5928694_1280.jpg"
              alt="Card Image"
            />
          </div>
        </div>
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Fire, Safety & Health</h3>
          <p class="card-paragraph">
          Prioritize safety with our expert solutions. We offer fire safety assessments, health audits, and compliance services to protect your property and people.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Fire, Safety & Health</h4>
              <p>Prioritize safety with our expert solutions. We offer fire safety assessments, health audits, and compliance services to protect your property and people.</p>
            </div>
            <img
              src="https://cdn.pixabay.com/photo/2017/08/25/06/58/firefighter-2679283_1280.jpg"
              alt="Card Image"
            />
          </div>
        </div>
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Plumbing & Drainage Services</h3>
          <p class="card-paragraph">
          Say goodbye to plumbing headaches. Our skilled team handles repairs, installations, and maintenance, ensuring smooth operations and minimal disruptions.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Plumbing & Drainage Services</h4>
              <p>Say goodbye to plumbing headaches. Our skilled team handles repairs, installations, and maintenance, ensuring smooth operations and minimal disruptions.</p>
            </div>
            <img
              src="https://cdn.pixabay.com/photo/2013/12/13/21/13/plumber-228010_1280.jpg"
              alt="Card Image"
            />
          </div>
        </div>
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Statutory Compliance</h3>
          <p class="card-paragraph">
          Stay on the right side of the law effortlessly. We manage permits, licenses, and regulatory paperwork, keeping your property compliant and stress-free.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Statutory Compliance</h4>
              <p>Stay on the right side of the law effortlessly. We manage permits, licenses, and regulatory paperwork, keeping your property compliant and stress-free.</p>
            </div>
            <img
              src="https://pulsehrm.com/wp-content/uploads/2020/08/Statutory-Compliance-in-Payroll-Banner.f.png"
              alt="Card Image"
            />
          </div>
        </div>
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Space Planning</h3>
          <p class="card-paragraph">
          Optimize your space for efficiency. Our tailored space planning solutions maximize workflow and functionality, enhancing productivity.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Space Planning</h4>
              <p>Optimize your space for efficiency. Our tailored space planning solutions maximize workflow and functionality, enhancing productivity.</p>
            </div>
            <img src="https://cdn.pixabay.com/photo/2017/08/25/20/01/gallery-2681238_1280.jpg" />
          </div>
        </div>
        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Water Management Services</h3>
          <p class="card-paragraph">
          Save water, save costs. Our water management strategies detect leaks, optimize irrigation, and promote sustainability for your property.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Water Management Services</h4>
              <p>Save water, save costs. Our water management strategies detect leaks, optimize irrigation, and promote sustainability for your property.</p>
            </div>
            <img
              src="https://images.unsplash.com/photo-1510148567806-766658eb5b86?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Card Image"
            />
          </div>
        </div>

        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Façade Cleaning</h3>
          <p class="card-paragraph">
          Make a great first impression. Our façade cleaning services remove dirt and stains, restoring the beauty of your building exterior.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Façade Cleaning</h4>
              <p>Make a great first impression. Our façade cleaning services remove dirt and stains, restoring the beauty of your building exterior.</p>
            </div>
            <img
              src="https://cdn.pixabay.com/photo/2019/06/10/12/15/building-cleaner-4264144_1280.jpg"
              alt="Card Image"
            />
          </div>
        </div>

        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Parking Arrangements</h3>
          <p class="card-paragraph">
          Ensure hassle-free parking. We design layouts and optimize traffic flow for efficient parking solutions tailored to your needs.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Parking Arrangements</h4>
              <p>Ensure hassle-free parking. We design layouts and optimize traffic flow for efficient parking solutions tailored to your needs.</p>
            </div>
            <img
              src="https://images.unsplash.com/photo-1580638964062-3ca2379e4a56?q=80&w=1993&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Card Image"
            />
          </div>
        </div>

        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Blind Cleaning</h3>
          <p class="card-paragraph">
          Refresh your space with clean blinds. Our professional cleaning removes dust and allergens, revitalizing your interior.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Blind Cleaning</h4>
              <p>Refresh your space with clean blinds. Our professional cleaning removes dust and allergens, revitalizing your interior.</p>
            </div>
            <img
              src="https://cdn.pixabay.com/photo/2021/01/18/16/56/blinds-5928692_1280.jpg"
              alt="Card Image"
            />
          </div>
        </div>

        <div data-aos="fade-up" class="card">
          <h3 class="card-heading">Sofa Chair Cleaning</h3>
          <p class="card-paragraph">
          Renew your upholstery. Our gentle cleaning methods eliminate dirt and odors, leaving your furniture looking and feeling like new.
          </p>
          <div class="card-image">
            <div class="card-hover-content">
              <h4>Sofa Chair Cleaning</h4>
              <p>Renew your upholstery. Our gentle cleaning methods eliminate dirt and odors, leaving your furniture looking and feeling like new.</p>
            </div>
            <img
              src="https://images.unsplash.com/photo-1686178827149-6d55c72d81df?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Card Image"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServicesCard;
