import React from 'react';
import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import Packages from './components/Packages/Packages';
import Shop from './components/Shop/Shop';
import About from './pages/About/About';
import Pages from './components/Pages/Pages';
import News from './components/News/News';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Packages' element={<Packages/>}/>
        <Route path='/Shop' element={<Shop/>}/>        
        <Route path='/About' element={<About/>}/>        
        <Route path='/Pages' element={<Pages/>}/>
        <Route path='/News' element={<News/>}/>
        <Route path='/Home' element={<Home/>}/>



        {/* </Route> */}
      </Routes>
    </Router>
    </>
  );
}

export default App;
