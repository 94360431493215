import React, {useEffect} from "react";
import '../Footer/Footer.css';
import { FcServices } from "react-icons/fc";
import { FiSend } from "react-icons/fi";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTripadvisor } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";


import Aos from "aos";
import "aos/dist/aos.css";


const Footer = () => {

    // use effect hook for scroll animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

    return(
        <section className="footer">

            <div className="secContent container">
                <div className="contactDiv flex">
                    <div data-aos="fade-up" className="text">
                        <small>Get IN TOUCH</small>
                        <h2>Book an online meeting with us to learn more.</h2>
                    </div>

                    <div className="inputDiv flex">
                        <input data-aos="fade-up" type="text" placeholder="Enter Email Address" />
                        <button data-aos="fade-up" className="btn flex" type="submit">
                        SEND <FiSend className="icon" />
                        </button>
                    </div>

                </div>

                <div className="footerCard flex">
                    <div className="footerIntro flex">
                        <div className="logoDiv">
                            <a href="#" className="logo flex">
                            <FcServices className="icon" /> Servant Free Services
                            </a>
                        </div>

                        <div data-aos="fade-up" className="footerParagraph">
                        Your Property, Our Priority: Safety-Driven Innovation, Bringing Your Modern Space Dreams to Life with Comprehensive Expertise.
                        </div>

                        <div data-aos="fade-up" className="footerSocials flex">
                        <FaSquareXTwitter className="icon" />
                        <FaYoutube className="icon" />
                        <FaInstagram className="icon" />
                        <FaTripadvisor className="icon" />
                        </div>

                    </div>

                    
                    <div className="footerLinks grid">

                        {/* Group One */}

                        <div data-aos="fade-up" data-aos-duration="3000" className="linkGroup">
                            <span className="groupTitle">
                                OUR SERVICES
                            </span>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> Services1
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> Services2
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> Services3
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> Services4
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> Services5
                            </li>

                        </div>

                    
                    
                     {/* Group two */}

                        <div data-aos="fade-up"  data-aos-duration="4000" className="linkGroup">
                            <span className="groupTitle">
                                WHO WE ARE
                            </span>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> ABOUT US
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> OUR MISSION
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> CONTACT US
                            </li>

                            {/* <li className="footerList flex">
                            <FiChevronRight className="icon" /> CONTACT US
                            </li> */}

                            {/* <li className="footerList flex">
                            <FiChevronRight className="icon" /> Trip Advisor
                            </li> */}

                        </div>

                        {/* Group Three */}

                        <div data-aos="fade-up" data-aos-duration="5000" className="linkGroup">
                            <span className="groupTitle">
                                TERMS & CONDITIONS
                            </span>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> TERMS
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> PRIVACY
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> BOOKINGS
                            </li>

                            <li className="footerList flex">
                            <FiChevronRight className="icon" /> REFUNDS
                            </li>

                            {/* <li className="footerList flex">
                            <FiChevronRight className="icon" /> BOOKING & REFUNDS
                            </li> */}

                        </div>
                    </div>

                    <div className="footerDiv flex">
                        <small>Servant Free Services</small>
                        <small>Copy rights reserved</small>
                    </div>

                </div>

            </div>

        </section>
    )
}

export default Footer;