import React from 'react';
import './Packages.css'; // Importing CSS file
import Navbar from '../Header/Navbar';

const Packages = () => {
  return (<>
    <Navbar/>
    <div className="packages-container">
      <h1>Our Packages</h1>
      <p>Welcome to our packages page! Here, you can find information about the various services we offer.</p>
      
      <div className="package">
        <h2>Basic Package</h2>
        <p>This package includes the essentials for getting started with our service.</p>
        <ul>
          <li>Feature 1</li>
          <li>Feature 2</li>
          <li>Feature 3</li>
        </ul>
        <p>Price: $X.XX</p>
      </div>
      
      <div className="package">
        <h2>Standard Package</h2>
        <p>This package includes more advanced features for those who need a bit more.</p>
        <ul>
          <li>Feature 1</li>
          <li>Feature 2</li>
          <li>Feature 3</li>
          <li>Feature 4</li>
        </ul>
        <p>Price: $XX.XX</p>
      </div>
      
      <div className="package">
        <h2>Premium Package</h2>
        <p>Our premium package offers the ultimate experience with all the bells and whistles.</p>
        <ul>
          <li>Feature 1</li>
          <li>Feature 2</li>
          <li>Feature 3</li>
          <li>Feature 4</li>
          <li>Feature 5</li>
        </ul>
        <p>Price: $XXX.XX</p>
      </div>
      
      <p>Contact us to learn more or to customize a package that suits your needs!</p>
    </div>
    </>
  );
};

export default Packages;
