import React, {useEffect} from "react";
import "../Hero/Hero.css";
import Aos from "aos";
import "aos/dist/aos.css";


const HeroSection = () => {

  // use effect hook for scroll animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="hero-section">
      {/* Hero Content */}
      <div className="hero-content">
        {/* <h1 data-aos="fade-up" className="hero-heading">
          Enabling Every Home To Be A Place Of Abundant Love, Happiness And
          Well-Being.
        </h1> */}
        <h1 data-aos="fade-up" className="hero-heading">
        "Your Property, Our Priority: Safety-Driven Innovation, Bringing Your Modern Space Dreams to Life with Comprehensive Expertise."
        </h1>
        <a data-aos="fade-up" href="#" className="common-button">Get In Touch</a>
      </div>

      {/* Hero Images */}
      <div data-aos="fade-up" className="hero-images">
        <div  className="hero-column">
          <div className="hero-card">
            <img
              src="https://cdn.pixabay.com/photo/2017/08/25/06/58/firefighter-2679283_1280.jpg"
              alt="Left Image 1"
            />
            <img
              src="https://cdn.pixabay.com/photo/2013/12/13/21/13/plumber-228010_1280.jpg"
              alt="Left Image 2"
            />
          </div>
        </div>

        <div className="hero-card center">
          <img
            src="https://cdn.pixabay.com/photo/2021/01/18/16/56/woman-5928694_1280.jpg"
            alt="Center Image"
          />
            <img
            src="https://cdn.pixabay.com/photo/2016/01/06/15/36/mailwoman-1124344_1280.jpg"
            alt="Center Image"
          />
        </div>

        <div className="hero-column">
          <div className="hero-card">
            <img
              src="https://cdn.pixabay.com/photo/2019/06/10/12/15/building-cleaner-4264144_1280.jpg"
              alt="Right Image 1"
            />
            <img
              src="https://cdn.pixabay.com/photo/2017/08/25/20/01/gallery-2681238_1280.jpg"
              alt="Right Image 2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
