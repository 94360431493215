import React from "react";
import "../Home/Home.css";
import Navbar from "../../components/Header/Navbar";
import HeroSection from "../../components/Hero/Hero";
import WaveAnimation from "../../components/WaveAnimation/WaveAnimation";
import WhatWeDoSection from "../../components/WhatWeDoSection/WhatWeDo";
import OurServices from "../../components/OurServices/OurServices";
import OurServicesCard from "../../components/OurServicesCard/OurServicesCard";
import Footer from "../../components/Footer/Footer";


const Home = () => {
    return(
        <>
        <Navbar />
        <HeroSection />
        <WhatWeDoSection />
        <WaveAnimation />
        <OurServices />
        <OurServicesCard />
        <WaveAnimation />
        <Footer />
        </>
    );
}

export default Home;