import React, { useState } from 'react';
import './Shop.css'; // Importing CSS file
import ProductCard from './ProductCard'; // Assuming ProductCard component for displaying products
import Navbar from '../Header/Navbar';

const Shop = () => {
  // Dummy product data
  const [products, setProducts] = useState([
    {
      id: 1,
      name: "Service 1",
      price: "$50",
      image: "blindcleaning.jpg" // Placeholder image name
    },
    {
      id: 2,
      name: "Service 2",
      price: "$75",
      image: "sofa chair cleaning.jpg" // Placeholder image name
    },
    {
      id: 3,
      name: "Service 3",
      price: "$100",
      image: "sofa chair cleaning.jpg" // Placeholder image name
    },
  ]);

  return (
    <>
    <Navbar/>
    <div className="shop-container">
      <h1>Our Services</h1>
      <div className="products">
        {products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
    </>
  );
};

export default Shop;
