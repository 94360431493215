import React from 'react';
import './About.css'; // Importing CSS file
import Navbar from '../../components/Header/Navbar';

const About = () => {
  return (
    <>
    <Navbar/>
    <div className="about-container">
      <h1>About Us</h1>
      <div className="about-cards">
        <div className="about-card">
          <h2>Our Mission</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus convallis vehicula.</p>
        </div>
        <div className="about-card">
          <h2>Our Vision</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla maximus convallis vehicula.</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
