import React from 'react'
import Navbar from '../Header/Navbar'

const News = () => {
  return (
<>
<Navbar/>
</> 
 )
}

export default News