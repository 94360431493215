import React, { useState } from "react";
import '../Header/Navbar.css';
import { FcServices } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { Link, Outlet } from "react-router-dom";

const Navbar = () => {
  const [active, setActive] = useState("navBar");

  // function to toggle navbar
  const showNav = () => {
    setActive("navBar activeNavbar");
  };
  // function to remove navbar
  const removeNavbar = () => {
    setActive("navBar");
  };

  return (
    <>
      <section className="navBarSection">
        <header className="header flex">
          <div className="logoDiv">
            <a href="#" className="logo flex">
              <h1>
                <FcServices className="icon" /> 
                Servant Free Services
              </h1>
            </a>
          </div>

          <div className={active}>
            <ul className="navLists flex">
              <li className="navItem">
                <Link to='/Home' className="navLink">
                  Home
                </Link>
              </li>
              <li className="navItem">
                <Link to='/Packages' className="navLink">
                  Packages
                </Link>
              </li>

              <li className="navItem">
              <Link to='/Shop' className="navLink">
                  Shop
                </Link>
              </li>
              <li className="navItem">
              <Link to='/About' className="navLink">
                  About
                </Link>
              </li>
              <li className="navItem">
              <Link to='/Pages' className="navLink">
                  Pages
                </Link>
              </li>
              <li className="navItem">
              <Link to='/News' className="navLink">
                  News
                </Link>
              </li>
              <li className="navItem">
              <Link to='/Contact' className="navLink">
                  Contact
                </Link>
              </li>

              <button className="btn">
                <a href="#">Book Now</a>
              </button>
            </ul>

            <div onClick={removeNavbar} className="closeNavbar">
              <AiFillCloseCircle className="icon" />
            </div>
          </div>
          <div onClick={showNav} className="toggleNavbar">
            <TbGridDots className="icon" />
          </div>
        </header>
      </section>
    </>
  );
};

export default Navbar;
