import React from 'react';

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      {/* <img src={require(`./assets/images/${product.image}`).default} alt={product.name} /> */}
      <h3>{product.name}</h3>
      <p>{product.price}</p>
      <button>Add to Cart</button>
    </div>
  );
};
export default ProductCard;