import React from "react";
import "../WaveAnimation/WaveAnimation.css";


const WaveAnimation = () => {
    return(
        <>
    <div className="wave_section">
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
        
        </>
    );
}

export default WaveAnimation;